import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRegisterUrl } from "../auth";

const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initiateRegister = async () => {
      try {
        const authUrl = await getRegisterUrl();
        window.location.href = authUrl;
      } catch (error) {
        console.error("Error during Register initiation:", error);
        navigate("/error"); // Redirect to an error page if Register fails
      }
    };

    initiateRegister();
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Redirecting you to Register...</p>
    </div>
  );
};

export default Register;
