import { SubscriptionValue } from "../types/SubscriptionValue";

export function setSubscriptionValue(
  subscription: string | null | undefined
): SubscriptionValue {
  const value = (subscription ?? "").toLocaleLowerCase();
  switch (value) {
    case "monthly":
    case "monthly2":
    case "monthly3":
    case "monthly9":
    case "monthly27":
    case "trial":
    case "ending":
    case "cancelled":
      localStorage.setItem("subscription", value);
      return value;
    default:
      localStorage.setItem("subscription", "");
  }
  return "";
}
