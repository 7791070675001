import { loadStripe } from "@stripe/stripe-js";
import { apiCall } from "../utils/api";
import "./SubscribeNow.css";

const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
const subscriptionOptions = [
  {
    price: "$2",
    credits: "1000",
    subscription: "monthly",
    benefits: [
      "1,000 monthly credits",
      "Up to 10 documents",
      "AI reviews of smaller documents",
      "Access to OpenAI (ChatGPT+)",
      "Character chats",
    ],
  },
  {
    price: "$9",
    credits: "6,000",
    subscription: "monthly9",
    benefits: [
      "6,000 monthly credits",
      "Up to 100 documents",
      "AI reviews of longer documents",
      "Access to OpenAI (ChatGPT+), Gemini, Nova, and Claude",
      "Enough credits for many Character chats",
      "Previous months credits rollover",
    ],
  },
  {
    price: "$27",
    credits: "25,000",
    subscription: "monthly27",
    bestValue: true,
    benefits: [
      "25,000 monthly credits",
      "Unlimited documents",
      "AI reviews of longer documents or book length manuscripts",
      "Access to OpenAI (ChatGPT+), Gemini, Nova, and Claude",
      "Excessive Character chatting!",
      // "Movie script conversion"
      "Previous months credits rollover",
    ],
  },
];

interface SubscribeNowProps {
  currentSubscription?: string;
}

const SubscribeNow: React.FC<SubscribeNowProps> = ({ currentSubscription }) => {
  if (currentSubscription === "ending" || currentSubscription === "cancelled") {
    currentSubscription = "";
  }
  const handleSubscribe = async (
    event: React.MouseEvent<HTMLButtonElement>,
    subscription: string
  ) => {
    event.preventDefault();
    try {
      const response = await apiCall("/create-checkout-session", "POST", {
        subscription,
      });

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.id,
        });
        if (error) {
          console.error("Stripe checkout error", error);
        }
      }
    } catch (error) {
      console.error("Error creating checkout session", error);
    }
  };

  return (
    <div className="subscription-container">
      <div className="subscription-grid">
        {subscriptionOptions.map((option) => (
          <div
            key={option.subscription}
            className={`card subscription-card ${
              option.bestValue ? "best-value" : ""
            } ${
              currentSubscription
                ? currentSubscription === option.subscription
                  ? "selected"
                  : "dimmed"
                : ""
            }`}
          >
            {option.bestValue && (
              <div className="best-value-badge">Best Value</div>
            )}
            <div className="card-body">
              <h3 className="price">
                {option.price}
                <span className="period">/month</span>
              </h3>
              <p className="credits">{option.credits} credits</p>
              <button
                className="btn btn-primary w-100"
                onClick={(event) => handleSubscribe(event, option.subscription)}
                disabled={currentSubscription === option.subscription}
              >
                {currentSubscription === option.subscription
                  ? "Current Plan"
                  : "Subscribe"}
              </button>
              <div className="benefits-list">
                <ul>
                  {option.benefits.map((benefit, index) => (
                    <li key={index} className="benefit-item">
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscribeNow;
