import axios from "axios";
import { jwtDecode } from "jwt-decode";
import pkceChallenge from "pkce-challenge";
import { apiCall } from "./utils/api";
import { getSubscriptionSpend } from "./utils/getSubscriptionSpend";
import { storeSubFromToken } from "./utils/storeSubFromToken";
import { setSubscriptionValue } from "./utils/setSubscriptionValue";

interface Config {
  clientId: string;
  redirectUri: string;
  authDomain: string;
  baseDomain: string;
}

const configByEnv = {
  local: {
    clientId: "1an5soi4r9mlvhqiq2okptpuel",
    redirectUri: "http://localhost:3000/callback",
    baseDomain: "http://localhost:3000",
    authDomain: "https://auth.story-starter-beta.com",
  } as Config,
  dev: {
    clientId: "1an5soi4r9mlvhqiq2okptpuel",
    redirectUri: "https://story-starter-beta.com/callback",
    baseDomain: "https://story-starter-beta.com",
    authDomain: "https://auth.story-starter-beta.com",
  } as Config,
  prod: {
    clientId: "bfdht8gjkthkmf162akjqfatr",
    redirectUri: "https://story-starter.com/callback",
    baseDomain: "https://story-starter.com",
    authDomain:
      "https://creativeaiwriter.auth.ap-southeast-2.amazoncognito.com",
  } as Config,
  prod2: {
    clientId: "bfdht8gjkthkmf162akjqfatr",
    redirectUri: "https://app.creativeaiwriter.com/callback",
    baseDomain: "https://app.creativeaiwriter.com",
    authDomain: "https://auth.creativeaiwriter.com",
  } as Config,
};

const getConfig = () => {
  let envName: string;
  switch (window.location.host) {
    case "localhost:3000":
      envName = "local";
      break;
    case "story-starter.com":
      envName = "prod";
      break;
    case "app.creativeaiwriter.com":
      envName = "prod2";
      break;
    default:
      envName = "dev";
      break;
  }
  return configByEnv[envName as keyof typeof configByEnv];
};

const config = getConfig();

const generatePkcePair = async () => {
  const { code_challenge, code_verifier } = await pkceChallenge();
  localStorage.setItem("pkceCodeVerifier", code_verifier);
  return code_challenge;
};

export const getRegisterUrl = async () => {
  const codeChallenge = await generatePkcePair();
  const authUrl = `${config.authDomain}/signup?response_type=code&client_id=${config.clientId}&redirect_uri=${config.redirectUri}&scope=email+profile+openid&code_challenge_method=S256&code_challenge=${codeChallenge}&prompt=login`;
  return authUrl;
};

export const getAuthUrl = async () => {
  const codeChallenge = await generatePkcePair();
  const authUrl = `${config.authDomain}/oauth2/authorize?response_type=code&client_id=${config.clientId}&redirect_uri=${config.redirectUri}&scope=email+profile+openid&code_challenge_method=S256&code_challenge=${codeChallenge}&prompt=login`;
  return authUrl;
};

export const getLogOutUrl = () => {
  const logoutUri = `${window.location.protocol}//${window.location.host}`;
  const logoutUrl = `${config.authDomain}/logout?client_id=${config.clientId}&logout_uri=${logoutUri}`;
  return logoutUrl;
};

export const exchangeCodeForTokens = async (code: string) => {
  const codeVerifier = localStorage.getItem("pkceCodeVerifier") as string;
  const tokenUrl = `${config.authDomain}/oauth2/token`;

  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: config.clientId,
    redirect_uri: config.redirectUri,
    code: code,
    code_verifier: codeVerifier,
  });

  try {
    const response = await axios.post(tokenUrl, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const tokens = response.data;
    localStorage.setItem("id_token", tokens.id_token);
    localStorage.setItem("access_token", tokens.access_token);
    localStorage.setItem("refresh_token", tokens.refresh_token);

    // Decode the ID Token to get the profile
    const decodedToken: any = jwtDecode(tokens.id_token);
    if (decodedToken) {
      const profile = {
        name: decodedToken.name,
        email: decodedToken.email,
        picture: decodedToken.profile ?? decodedToken.picture,
      };
      localStorage.setItem("profile", JSON.stringify(profile));
    }

    try {
      const response = await ensureGetUser();
      const subscriptionSpendData = getSubscriptionSpend(response);

      // Update localStorage whenever subscriptionSpend changes
      localStorage.setItem(
        "subscriptionSpend",
        String(subscriptionSpendData.creditsConsumed)
      );
      localStorage.setItem(
        "subscriptionLimit",
        String(subscriptionSpendData.creditLimit)
      );
      setSubscriptionValue(response.subscription);
    } catch (err) {
      console.error("Error getting for subscription:", err);
    }

    // Retrieve redirect path from localStorage
    const redirectPath =
      localStorage.getItem("postLoginRedirect") || "/dashboard";

    // Keep "pkceCodeVerifier" as it is required if the user navigates back and "signs in" again

    // cleanup
    localStorage.removeItem("postLoginRedirect");

    // Redirect to the saved path
    if (validateLocalPath(redirectPath)) {
      window.location.href = redirectPath;
    } else {
      window.location.href = "/dashboard";
    }
  } catch (error) {
    console.error("Error exchanging code for tokens:", error);
    throw error;
  }
};

export const refreshAccessToken = async (): Promise<string> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  const tokenUrl = `${config.authDomain}/oauth2/token`;

  const params = new URLSearchParams({
    grant_type: "refresh_token",
    client_id: config.clientId,
    refresh_token: refreshToken,
  });

  try {
    const response = await axios.post(tokenUrl, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.status !== 200) {
      /*
      400 Bad Request
      {"error":"invalid_grant"}
      */
      if (response.data.error === "invalid_grant") {
        throw new Error("invalid_grant");
      } else {
        throw new Error("refresh_token_error");
      }
    }

    const tokens = response.data;
    /* example response:
    OK 200
    {
        "id_token": "...",
        "access_token": "...",
        "expires_in": 3600,
        "token_type": "Bearer"
    }
    */
    localStorage.setItem("access_token", tokens.access_token);
    localStorage.setItem("id_token", tokens.id_token);
    storeSubFromToken(tokens.id_token);

    return tokens.access_token;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const clearTokens = () => {
  localStorage.removeItem("id_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("pkceCodeVerifier");
  localStorage.removeItem("subscription");
  localStorage.removeItem("profile");
};

async function ensureGetUser() {
  const response1 = await apiCall("/get-user", "POST", { action: "login" });

  if (response1.signUpStatus) {
    return response1;
  }

  return await apiCall("/get-user", "POST", { action: "login" });
}

function validateLocalPath(path: string): boolean {
  try {
    // Use a dummy base URL to parse the path
    const baseUrl = config.baseDomain;
    const url = new URL(path, baseUrl);

    // Check if the origin is the same as the base URL
    if (url.origin !== baseUrl) {
      return false;
    }

    // Ensure the path starts with "/"
    return path.startsWith("/");
  } catch {
    // If parsing fails, the path is invalid
    return false;
  }
}
