export const makeApiPath = (path: string, auth = true): string => {
  const version =
    window.location.host === "story-starter.com"
      ? "v1"
      : window.location.host === "app.creativeaiwriter.com"
      ? "v1"
      : "v0";
  const p = auth ? "" : "/p";
  const basePath = `${p}/${version}`;
  return `${process.env.REACT_APP_SS_API_URL}${basePath}${path}`;
};
