function simpleHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (Math.imul(31, hash) + str.charCodeAt(i)) | 0;
  }
  return Math.abs(hash).toString(36);
}

export const storeSubFromToken = (token: string) => {
  const tokenPayload = JSON.parse(atob((token ?? "").split(".")[1]));
  localStorage.setItem("sub", tokenPayload.sub);
  localStorage.setItem("uid", simpleHash(tokenPayload.sub));
};
