import React from "react";
import { Alert } from "react-bootstrap";

interface ErrorAlertProps {
  errorMessages: string[];
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ errorMessages }) => {
  if (errorMessages?.length === 0) {
    return null;
  }

  // Specific error, require a new login
  const invalidGrant = errorMessages.some(
    (message) =>
      message === "Request failed with status code 401" ||
      message === "login_flow_error" ||
      message === "invalid_grant" ||
      message === "refresh_token_error" ||
      message === "no_access_token"
  );

  const outOfCredit = errorMessages?.[0] === "ERROR_LACK_CREDIT";

  if (invalidGrant || outOfCredit) {
    errorMessages = [];
  }

  let redirectUrl = window.location.pathname + window.location.search;

  if (
    window.location.pathname === "/callback" ||
    window.location.pathname === "/logout"
  ) {
    redirectUrl = "/dashboard";
  }

  return (
    <>
      {errorMessages.length > 0 && (
        <Alert
          variant="danger"
          onClose={() => {
            errorMessages = [];
          }}
          dismissible
        >
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}
      {invalidGrant && (
        <Alert variant="danger">
          <p>
            Your session has expired. Please{" "}
            <a href={`/logout?redirect=${encodeURIComponent(redirectUrl)}`}>
              log out
            </a>{" "}
            and then login again...
          </p>
        </Alert>
      )}
      {outOfCredit && (
        <Alert variant="danger">
          <p>
            You are out of credits. Please <a href="/settings">subscribe</a> to
            get more credits.
          </p>
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
